<template>
    <div>
        <ul class="footerLinks">
            <li class="footerLinks-entry">
                Contact
            </li>
            <li class="footerLinks-entry">
                Commissioned Work
                <a
                    href="https://www.considerpablo.com"
                    class="footerLinks-link"
                    rel="noopener"
                >www.considerpablo.com</a>
                <a
                    href="mailto:contact@considerpablo.com"
                    class="footerLinks-link"
                    rel="noopener"
                >contact@considerpablo.com</a>
            </li>
            <li class="footerLinks-entry">
                Booking
                <a
                    href="mailto:kilian@orangepeel-agency.ch"
                    class="footerLinks-link"
                    rel="noopener"
                >kilian@orangepeel-agency.ch</a>
            </li>
            <li class="footerLinks-entry">
                Management <a
                    href="mailto:rene.renner@lmeworldwide.com"
                    class="footerLinks-link"
                    rel="noopener"
                >rene.renner@lmeworldwide.com</a>
            </li>
            <li class="footerLinks-entry">
                Website By
                <a
                    href="https://noordermeer.ch"
                    rel="noopener"
                    class="footerLinks-link"
                >Joris Noordermeer (Code)</a>
                <a
                    href="https://rubenfeurer.com"
                    class="footerLinks-link"
                    rel="noopener"
                >Ruben Feurer (Design)</a>
            </li>
        </ul>
    </div>
</template>
