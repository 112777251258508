[
    {
        "title": "Spotify",
        "url": "https://open.spotify.com/artist/2OIJq28O1lXrZ2x3NGSk8P?si=CByy8uzMQbWgOVOESWP-zg"
    },
    {
        "title": "Apple Music",
        "url": "https://music.apple.com/ch/artist/pablo-nouvelle/651215409"
    },
    {
        "title": "Soundcloud",
        "url": "https://soundcloud.com/pablo-nouvelle"
    },
    {
        "title": "YouTube",
        "url": "https://www.youtube.com/channel/UCTxtMNKefSKfQOK0kGjXUew"
    },
    {
        "title": "Instagram",
        "url": "https://www.instagram.com/pablonouvelle/?hl=en"
    },
    {
        "title": "Facebook",
        "url": "https://www.facebook.com/pablonouvellemusic/"
    }
]
